import * as React from 'react';

import { Footer, NavMenu, PageTitle } from '../components/shared';

import { Container } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';

export const ThankYouPage = () => {
  return (
    <>
      <PageTitle subtitle="Thank You" />
      <NavMenu fixed noNav />
      <Container className="wsmainwp text-center">
        <StaticImage src="../images/success.png" className="img-fluid" width={500} alt="logo image" placeholder="none" />
        <h1>Thank you for ordering your Property Tax Playbook. </h1>
        <p className="p-xl">
          A team member is reviewing the computations in your Playbook. After our review, we will email you a link to download your Playbook. You should recieve
          an email with your Playbook URL within one hour of purchase.
        </p>
        <p className="p-xl">
          If you have any questions in the meantime, please contact us at{' '}
          <a className="purple-color tra-purple-hover" href="mailto:support@propertytaxplaybook.com">
            support@propertytaxplaybook.com
          </a>
        </p>
      </Container>
      <Footer fixed />
    </>
  );
};

export default ThankYouPage;
